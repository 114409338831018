import { clickTheWasItHelpfulButtons, submitWasItHelpfulForm } from '@wix/bi-logger-new-help-center/v2';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { BI } from '../../constants';
import { Context } from '../../context';
import { useBI } from '../../hooks/useBI';

export const useArticleVisibleFeedbackBi = (articleId: string) => {
  const { sendBIEvent } = useBI();
  const { sessionBI } = useContext(Context);
  const { locale } = useRouter();

  const commonProps = {
    item_id: articleId,
    source_name: BI.SOURCE_NAMES.ARTICLE,
    kb_lang: locale,
    self_service_session_id: sessionBI.selfServiceSessionId || undefined,
  };

  const onVoteOptionClick = async (positive: boolean, clickedText: string) => {
    await sendBIEvent(
      clickTheWasItHelpfulButtons({
        ...commonProps,
        thumbs_up: positive,
        clicked_text: clickedText,
      })
    );
  };

  const onSendFeedback = async (clickedText: string, feedbackText: string) => {
    await sendBIEvent(
      submitWasItHelpfulForm({
        ...commonProps,
        thumbs_up: false,
        clicked_text: clickedText,
        free_text: feedbackText.trim(),
      })
    );
  };
  return { onVoteOptionClick, onSendFeedback };
 };
