import { LOCALES } from '../../../constants';

const withSpread = (object: any, [key, value]: any) => ({
  ...object,
  [key]: value,
});

const upSellVeloArticleIds = ['d80ec8a4-7787-4330-9022-aa1611094b6a'];
const customPriceVeloArticleIds = ['eb00e5a9-fb49-4ebe-9b6e-940cf1fb4a9f'];
const dataSearchVeloArticleIds = [
  '2affd059-ec0e-441a-8e40-c2fca557ca3e',
  '9fe2393c-4cd1-4156-b089-e90b2b336282',
  'c567dd27-f630-4a93-b1b5-e07b01650920',
];
const customRegVeloArticleIds = ['a6d03967-041c-4521-acf6-70d8fdd399b4'];
const sendgridVeloArticleIds = ['7bd7f2b5-cadd-450e-9d4c-d5b9b5f6bf3c'];
const oldBlogMigrateArticleIds = [
  '6f26af10-0e5b-44e8-a92f-f89d424822fe',
  '02d9cada-8be1-4e9f-8665-45ee4c2df77f',
  'ac456f04-ed22-4826-894a-3ab31ff25995',
  '2ebf8ff4-05c7-47a7-af74-ae9da68e065b',
  '705dd467-536d-47c0-b821-62995ae4bddd',
  'cd385ceb-6d85-47eb-b124-d89357274f66',
];
const seoCategories = [
  'fa5f8bc8-dafb-43aa-8fe9-e0ec51c79319',
  '7d248267-f5c9-4a01-9fda-c6edc90c9e06',
  '349cb1cf-1db7-4d68-8084-c95844a1efab',
];
const blogCategories = [
  'f37cf1e3-4076-4483-80c4-1872b31d52a6',
  'e0bfa53c-bfe5-4038-afa5-81118148e21d',
  'ef6b5a2c-91f5-48cb-94a0-6b229111bb1d',
  'ecd480c4-646d-4205-b7a6-51d52842bc0a',
];
const restaurantCategories = [
  '75f5ff19-2c60-4556-a6f9-b434b8563cbd',
  '92bd9962-eb28-4f5b-8639-a59e54bf54b2',
  'ac70a5d7-416d-410f-bc52-006893d3cd61',
  'b9620832-a8a0-404f-9574-e5b66334a269',
  '3169737a-5e73-4fe8-936c-f4297fc3d9eb',
  'c99c72c3-2e14-456d-8f30-dd4c43f076d1',
  'dd43f02c-4977-46cc-818c-72dbd3cf85b9',
  '3d1318c1-f93c-438c-bd39-bdc142db4978',
  'f542cce5-2b97-41a7-be60-7d06696181a4',
];
const veloCategories = [
  '23b21dab-8236-4c21-a4be-5a80c6daab82',
  'dc33b1ac-25f4-4964-9c20-dda07e5de954',
  '6ee79b11-f7c8-4b5d-b6f6-639824901c4e',
  '319c36de-720b-439b-864b-ad4941a63113',
  '7c4251d0-c7db-43d5-ba14-7cc738a680cc',
  'a81cbf36-4bd6-4234-b4eb-b2d8d410a76a',
  'dd6511ea-4a66-42cc-a34c-1825160a1f98',
  '2a172aa8-7c43-4229-a469-443bf772011b',
  'c9c12fed-0155-4884-b40f-2faec645ea82',
  'd45c65a6-e681-435e-acf0-bbcbc46e2ad8',
  'f1858a48-23a8-489d-a882-9c7103b22913',
  '4f0a15fd-388e-412c-9267-de027344ccfe',
  '1d114ed3-9bdf-4c7b-b8ec-57fa970637c5',
  '15f17a17-9fc2-41ff-bd11-a3c8b58a8e00',
  '0a42dd43-fa1b-4de7-9959-31e9c0751c45',
  '0cfef324-83dd-491c-bcdf-bd1d2a71d603',
  'f49d4693-effe-438d-89b4-a9a1e3592ed1',
  'efd1271b-6e90-458c-bc60-a5ec5f4180e8',
  '4f23712c-c750-4a85-94bc-df1a45bff75e',
  'be0b8eb8-83b9-4f18-ad6e-ef96bfcc8d32',
];
const expertsCategoriesToHide = [
  'e7702d45-984b-45a5-87c0-c3c5c09d0cf2',
  '93b3fd1e-5d5b-4bd4-8629-560fc49197ec',
];

const enArticleIdsMap = {
  ...upSellVeloArticleIds
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.add.this.feature',
          description: 'hire.partner.description.upsell.velo',
          cta: 'hire.partner.cta.starting.from.hundred',
        },
        link: 'https://www.wix.com/marketplace/dynamic-brief?entry=add-cross-selling&serviceType=7&innerType=8&origin=kb-cross-sell',
      },
    ])
    .reduce(withSpread, {}),
  ...customPriceVeloArticleIds
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.add.this.feature',
          description: 'hire.partner.description.custom.price.velo',
          cta: 'hire.partner.cta.starting.from.hundred',
        },
        link: 'https://www.wix.com/marketplace/dynamic-brief?entry=quick-code-solutions&serviceType=7&innerType=9&origin=kb-custom-price',
      },
    ])
    .reduce(withSpread, {}),
  ...dataSearchVeloArticleIds
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.add.this.feature',
          description: 'hire.partner.description.data.search.velo',
          cta: 'hire.partner.cta.starting.from.hundred',
        },
        link: 'https://www.wix.com/marketplace/dynamic-brief?entry=quick-code-solutions&serviceType=7&innerType=9&origin=kb-collection-data-search',
      },
    ])
    .reduce(withSpread, {}),
  ...customRegVeloArticleIds
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.add.this.feature',
          description: 'hire.partner.description.custom.reg.velo',
          cta: 'hire.partner.cta.starting.from.hundred.and.sixty',
        },
        link: 'https://www.wix.com/marketplace/dynamic-brief?entry=quick-code-solutions&serviceType=7&innerType=9&origin=kb-custom-member-signup',
      },
    ])
    .reduce(withSpread, {}),
  ...sendgridVeloArticleIds
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.add.this.feature',
          description: 'hire.partner.description.sendgrid.velo',
          cta: 'hire.partner.cta.starting.from.hundred',
        },
        link: 'https://www.wix.com/marketplace/dynamic-brief?entry=quick-code-solutions&serviceType=7&innerType=9&origin=kb-form-submission-email',
      },
    ])
    .reduce(withSpread, {}),
  ...oldBlogMigrateArticleIds
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.add.this.feature',
          description: 'hire.partner.description.old.blog.migrate',
          cta: 'hire.partner.cta.start.now',
        },
        link: 'https://www.wix.com/partners/move-blog',
      },
    ])
    .reduce(withSpread, {}),
};
const enCategoryIdsMap = {
  ...seoCategories
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.hire.seo.partner',
          description: 'hire.partner.description.seo',
          cta: 'hire.partner.cta.get.started',
        },
        link: 'https://www.wix.com/marketplace/brief/match?entry=promote&serviceType=1&innerType=1&origin=seo-articles',
      },
    ])
    .reduce(withSpread, {}),
  ...blogCategories
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.hire.pro.writer',
          description: 'hire.partner.description.blog',
          cta: 'hire.partner.cta.get.started',
        },
        link: 'https://www.wix.com/marketplace/brief/match?entry=help-center&serviceType=4&innerType=3&origin=blog-articles',
      },
    ])
    .reduce(withSpread, {}),
  ...restaurantCategories
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.hire.wix.partner',
          description: 'hire.partner.description.restaurant',
          cta: 'hire.partner.cta.start.now',
        },
        link: 'https://www.wix.com/marketplace/brief/match?entry=help-center&serviceType=4&innerType=7&origin=restaurants-articles',
      },
    ])
    .reduce(withSpread, {}),
  ...veloCategories
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.hire.velo.developer',
          description: 'hire.partner.description.velo',
          cta: 'hire.partner.cta.get.started',
        },
        link: 'https://www.wix.com/marketplace/brief/match?entry=velo&serviceType=1&innerType=1&origin=velo-articles',
      },
    ])
    .reduce(withSpread, {}),
};
const ptCategoryIdsMap = {
  ...seoCategories
    .map((key) => [
      key,
      {
        texts: {
          title: 'hire.partner.title.hire.seo.partner',
          description: 'hire.partner.description.seo',
          cta: 'hire.partner.cta.start.now',
        },
        link: 'https://www.wix.com/marketplace/brief/match?entry=promote&serviceType=1&innerType=1&origin=seo-articles',
      },
    ])
    .reduce(withSpread, {}),
};

const defaultContentNewTexts = {
  texts: {
    title: 'hire-a-partner.title',
    description: 'hire-a-partner.description',
    cta: 'hire-a-partner.cta',
  },
  link: 'https://www.wix.com/marketplace/brief-auto-match?entry=help-center&origin=help-center-articles',
};

type HirePartnerContent = {
  texts: {
    title: string;
    description: string;
    cta: string;
  };
  link: string;
} | null;

export const getContent = (
  articleId: string,
  categoryId: string,
  locale: string
): HirePartnerContent => {
  if (expertsCategoriesToHide.includes(categoryId)) {
    return null;
  }
  if (locale === LOCALES.PT) {
    if (ptCategoryIdsMap[categoryId]) {
      return ptCategoryIdsMap[categoryId];
    } else {
      return defaultContentNewTexts;
    }
  } else if (locale === LOCALES.EN) {
    if (enArticleIdsMap[articleId]) {
      return enArticleIdsMap[articleId];
    } else if (enCategoryIdsMap[categoryId]) {
      return enCategoryIdsMap[categoryId];
    } else {
      return defaultContentNewTexts;
    }
  }
  return null;
};
