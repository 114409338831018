import { DATA_HOOKS } from '../dataHooks';

export const getAgentToolbarHeight = () =>
  (document?.querySelector('.user-toolbar-expansion') as HTMLElement)
    ?.offsetHeight || 0;

export const getBreadcrumbsHeight = () =>
  (
    document?.querySelector(
      `div[data-hook="${DATA_HOOKS.BREADCRUMBS}"]`
    ) as HTMLElement
  )?.offsetHeight || 0;

export const getSubHeaderHeight = () =>
  (
    document?.querySelector(
      `div[data-hook="${DATA_HOOKS.KB_SUBHEADER}"]`
    ) as HTMLElement
  )?.offsetHeight || 0;

export const getTopOffsetForScrollToArticlePage = () =>
  getAgentToolbarHeight() + getSubHeaderHeight() + getBreadcrumbsHeight();
