import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { useState, useEffect } from 'react';
import {
  helpCenterBannerView,
  helpCenterBannerClick,
} from '@wix/bi-logger-new-help-center/v2';
import { useRouter } from 'next/router';
import { BI } from '../constants';
import { PAGES } from '../types';
import { useBI } from '../hooks/useBI';
import {
  mapPageTypeToSourceName,
  mapArticleTypeToItemType,
} from './bi';
import { Article, Category } from '@wix/answers-api';
import { stripHTML } from './text';

export const useArticlePageBi = (article: Article) => {
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();
  const [wasArticleHelpfulViewedSent, setWasArticleHelpfulViewedSent] = useState(false);

  useEffect(() => {
    setWasArticleHelpfulViewedSent(false);
  }, [article.id]);

  const breadCrumbLinkClick = async (
    category: Category,
    clickedItemOrder?: number
  ) => {
    await sendBIEvent(
      pageClick({
        source_name: mapPageTypeToSourceName(PAGES.ARTICLE),
        item_id: article.id,
        item_type: mapArticleTypeToItemType(article.type),
        clicked_item_type: BI.CLICKED_ITEM_TYPES.BREADCRUMBS,
        clicked_text: category.name,
        clicked_url: category.url,
        clicked_item_id: category.id,
        clicked_item_order: clickedItemOrder?.toString(),
        is_page_content_click: false,
      })
    );
  };

  const homePageClick = async (clickedText: string) => {
    if (window) {
      await sendBIEvent(
        pageClick({
          source_name: mapPageTypeToSourceName(PAGES.ARTICLE),
          item_id: article.id,
          item_type: mapArticleTypeToItemType(article.type),
          clicked_item_type: BI.CLICKED_ITEM_TYPES.BREADCRUMBS,
          clicked_text: clickedText,
          clicked_url: `${window.location.origin}/${locale}`,
          clicked_item_order: '1',
          clicked_item_id: undefined,
          is_page_content_click: false,
        })
      );
    }
  };

  const articleIsBeingAbTestedView = async (bannerText: string) => {
    if (wasArticleHelpfulViewedSent) {
      return;
    } else {
      await sendBIEvent(
        helpCenterBannerView({
          banner_text: stripHTML(bannerText),
          banner_name: BI.BANNER_NAME.ARTICLE_IN_AB_TEST,
          has_cta: true,
          source_name: mapPageTypeToSourceName(PAGES.ARTICLE),
          is_resolved: false,
          kb_lang: locale,
          item_type: mapArticleTypeToItemType(article.type),
          item_id: article.id,
          banner_language: locale,
        })
      );
      setWasArticleHelpfulViewedSent(true);
    }
  };

  const articleIsBeingAbTestedClick = async (bannerText: string, clickedText: string, clickedUrl: string) => {
    await sendBIEvent(
      helpCenterBannerClick({
        clicked_text: clickedText,
        banner_text: stripHTML(bannerText),
        banner_name: BI.BANNER_NAME.ARTICLE_IN_AB_TEST,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.LINK,
        source_name: mapPageTypeToSourceName(PAGES.ARTICLE),
        is_resolved: false,
        kb_lang: locale,
        item_type: mapArticleTypeToItemType(article.type),
        item_id: article.id,
        banner_language: locale,
        clicked_url: `${location.origin}/${locale}${clickedUrl}`,
      })
    );
  };
  return { breadCrumbLinkClick, homePageClick, articleIsBeingAbTestedView, articleIsBeingAbTestedClick };
};
