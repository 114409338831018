import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from '@wix/wix-i18n-config';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import Image from 'next/image';
import { Box, Text, TextButton } from '@wix/design-system';
import { ExternalLinkSmall as ExternalLinkIcon } from '@wix/wix-ui-icons-common';
import { Context } from '../../../../context';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { getContent } from '../../../ArticleSidebar/HirePartner/contentLogic';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../../../hooks/useBiOnVisibleItem';
import { DATA_HOOKS } from '../../../../dataHooks';
import { useBI } from '../../../../hooks/useBI';
import css from './index.module.scss';
import { BI } from '../../../../constants';

type HirePartnerBannerProps = {
  articleId: string;
  categoryId: string;
};

export const HirePartnerBanner: FunctionComponent<HirePartnerBannerProps> = ({ articleId, categoryId }: HirePartnerBannerProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { isMobile } = useContext(Context);
  const windowSize = useWindowSize();
  const { sendBIEvent } = useBI();
  const [isMobileView, setIsMobileView] = useState<boolean>(isMobile);
  const { itemRef, resetItemOnPageEvent } = useBiOnVisibleItem(ItemOnPageEventSource.HIRE_A_PARTNER,
    {
      item_id: articleId,
    });

  const content = getContent(articleId, categoryId, locale as string);

  const onClick = async () => {
    if (content) {
      await sendBIEvent(
        pageClick({
          source_name: BI.SOURCE_NAMES.ARTICLE,
          kb_lang: locale as string,
          item_id: articleId,
          clicked_item_type: BI.CLICKED_ITEM_TYPES.HIRE_A_WIX_PARTNER,
          clicked_url: content.link,
          clicked_text: t(content.texts.cta),
          is_page_content_click: false,
        })
      );
    }
  };

  useEffect(() => {
    resetItemOnPageEvent();
  }, [articleId, resetItemOnPageEvent]);

  useEffect(() => {
    if (windowSize.width) {
      setIsMobileView(windowSize.width <= 767);
    }
  }, [windowSize.width]);

  const HIRE_PARTNER_BANNER_IMAGE =
    'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/hire-partner-banner/hire-partner-banner.jpg';

  if (content === null) {
    return null;
  }

  return !isMobileView ? (
    <Box className={css.hirePartnerBanner} dataHook={DATA_HOOKS.HIRE_PARTNER}>
      <Box direction="vertical" className={css.hirePartnerBannerContent}>
        <Text dataHook={DATA_HOOKS.HIRE_PARTNER_TITLE} className={css.hirePartnerBannerTitle}>
          {t(content.texts.title)}
        </Text>
        <Text size="small" className={css.hirePartnerBannerDescription} maxLines={3}>
          {t(content.texts.description)}
        </Text>
        <Box ref={itemRef}>
          <TextButton
            dataHook={DATA_HOOKS.HIRE_PARTNER_CTA}
            href={content.link}
            className={css.hirePartnerBannerCTA}
            size="small"
            as="a"
            target="_blank"
            onClick={onClick}
            suffixIcon={<ExternalLinkIcon />}>
            {t('hire-partner-banner.cta')}
          </TextButton>
        </Box>
      </Box>
      <Image
        alt={''}
        src={HIRE_PARTNER_BANNER_IMAGE}
        width={290}
        height={168}
        loading="lazy"
        quality={100}
      />
    </Box>
  ) : (
    <Box className={css.hirePartnerBanner} direction="vertical" dataHook={DATA_HOOKS.HIRE_PARTNER}>
      <Text dataHook={DATA_HOOKS.HIRE_PARTNER_TITLE} className={css.hirePartnerBannerTitle}>
        {t(content.texts.title)}
      </Text>
      <Image
        alt={''}
        src={HIRE_PARTNER_BANNER_IMAGE}
        loading="lazy"
        quality={100}
        width={707}
        height={471}
        layout="responsive"
      />
      <Text size="small" className={css.hirePartnerBannerDescription} maxLines={3}>
        {t(content.texts.description)}
      </Text>
      <Box ref={itemRef}>
        <TextButton
          dataHook={DATA_HOOKS.HIRE_PARTNER_CTA}
          href={content.link}
          className={css.hirePartnerBannerCTA}
          size="small"
          as="a"
          target="_blank"
          onClick={onClick}
          suffixIcon={<ExternalLinkIcon />}>
          {t('hire-partner-banner.cta')}
        </TextButton>
      </Box>
    </Box>
  );
};
