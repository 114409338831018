import { ArticleAdditionalFeedbackTypeNegative } from '@wix/answers-api';
import { Box, Radio } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../../../dataHooks';
import css from './index.module.scss';

export type UnhelpfulOptionsProps = {
  unhelpfulOption?: ArticleAdditionalFeedbackTypeNegative;
  onChangeOption: (option: ArticleAdditionalFeedbackTypeNegative) => void;
};

export const UnhelpfulOptions: FunctionComponent<UnhelpfulOptionsProps> = ({
  unhelpfulOption,
  onChangeOption,
}: UnhelpfulOptionsProps) => {
  const { t } = useTranslation();

  const translationPrefix = 'article-feedback.additional.unhelpful.option.';

  return (
    <Box direction='vertical' dataHook={DATA_HOOKS.UNHELPFUL_OPTIONS} className={css.wrapper}>
      <Radio label={t(`${translationPrefix}${ArticleAdditionalFeedbackTypeNegative.QUESTION_UNANSWERED}`)}
        className={css.unhelpfulOption}
        checked={unhelpfulOption === ArticleAdditionalFeedbackTypeNegative.QUESTION_UNANSWERED}
        onChange={() => onChangeOption(ArticleAdditionalFeedbackTypeNegative.QUESTION_UNANSWERED)} />

      <Radio label={t(`${translationPrefix}${ArticleAdditionalFeedbackTypeNegative.CONFUSING}`)}
        className={css.unhelpfulOption}
        checked={unhelpfulOption === ArticleAdditionalFeedbackTypeNegative.CONFUSING}
        onChange={() => onChangeOption(ArticleAdditionalFeedbackTypeNegative.CONFUSING)} />

      <Radio label={t(`${translationPrefix}${ArticleAdditionalFeedbackTypeNegative.MISSING_FEATURE}`)}
        className={css.unhelpfulOption}
        checked={unhelpfulOption === ArticleAdditionalFeedbackTypeNegative.MISSING_FEATURE}
        onChange={() => onChangeOption(ArticleAdditionalFeedbackTypeNegative.MISSING_FEATURE)} />

      <Radio label={t(`${translationPrefix}${ArticleAdditionalFeedbackTypeNegative.POLICY_ISSUE}`)}
        className={css.unhelpfulOption}
        checked={unhelpfulOption === ArticleAdditionalFeedbackTypeNegative.POLICY_ISSUE}
        onChange={() => onChangeOption(ArticleAdditionalFeedbackTypeNegative.POLICY_ISSUE)} />

      <Radio label={t(`${translationPrefix}${ArticleAdditionalFeedbackTypeNegative.OTHER}`)}
        className={css.unhelpfulOption}
        checked={unhelpfulOption === ArticleAdditionalFeedbackTypeNegative.OTHER}
        onChange={() => onChangeOption(ArticleAdditionalFeedbackTypeNegative.OTHER)} />
    </Box>
  );
};
