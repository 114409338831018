import { useRouter } from 'next/router';
import React, {
  FunctionComponent,
  useContext,
} from 'react';
import { Article } from '@wix/answers-api';
import { Box, Text } from '@wix/design-system';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { useTranslation } from '@wix/wix-i18n-config';
import { ArticleMediumIcon } from '../../../../icons/ArticleMediumIcon';
import { useBI } from '../../../../hooks/useBI';
import { mapPageTypeToSourceName } from '../../../../utils/bi';
import { BI } from '../../../../constants';
import { PAGES } from '../../../../types';
import { Context } from '../../../../context';
import { DATA_HOOKS } from '../../../../dataHooks';
import { InnerLink } from '../../../InnerLink';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../../../hooks/useBiOnVisibleItem';
import css from './index.module.scss';

export type RelatedContentProps = {
  relatedArticles: Article[];
};

export const RelatedContent: FunctionComponent<RelatedContentProps> = ({
  relatedArticles
}: RelatedContentProps) => {
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();
  const { pageTemplateData } = useContext(Context);
  const { itemRef } = useBiOnVisibleItem(ItemOnPageEventSource.RELATED_CONTENT);

  const relatedContentClick = async (
    text: string,
    article: Article,
    order: string
  ) => {
    await sendBIEvent(
      pageClick({
        source_name: mapPageTypeToSourceName(PAGES.ARTICLE),
        kb_lang: locale as string,
        item_id: pageTemplateData.itemId,
        item_type: pageTemplateData.itemType,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.RELATED_CONTENT,
        clicked_url: `/${locale}${article.uri}`,
        clicked_text: text,
        clicked_item_order: order,
        clicked_item_id: article.id,
        is_page_content_click: false,
      })
    );
  };

  return relatedArticles.length > 0 ? (
    <Box direction="vertical" gap="24px" className={css.relatedContent}>
      <Text size="medium" weight="bold">{t('related-content.title')}</Text>
      <Box ref={itemRef} gap="12px" className={css.relatedContentItems}>
        {relatedArticles.map((relatedArticle: Article, index: number) =>
          <InnerLink
            key={relatedArticle.id}
            href={relatedArticle.uri}
            className={css.relatedArticle}
            dataHook={DATA_HOOKS.RELATED_ARTICLE_LINK}
            onClick={async (e) => {
              const text = (e.target as HTMLElement).textContent as string;
              await relatedContentClick(text, relatedArticle, `${index + 1}`);
            }}
          >
            <Box className={css.relatedContentItem}>
              <ArticleMediumIcon />
              <Text dataHook={DATA_HOOKS.RELATED_ARTICLE_TEXT}
                ellipsis>{relatedArticle.title}</Text>
            </Box>
          </InnerLink>
        )}
      </Box>
    </Box>
  ) : null;
};
