import { Badge, Box, Heading, Text } from '@wix/design-system';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { useTranslation } from '@wix/wix-i18n-config';
import classNames from 'classnames';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { BI, BILLING_AND_PREMIUM_CATEGORIES, CHATBOT_URL, EXPERIMENTS, LOCAL_STORAGE_PREFIX } from '../../constants';
import { DATA_HOOKS } from '../../dataHooks';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../hooks/useBiOnVisibleItem';
import { setSessionStorage } from '../../utils/webStorageWrapper';
import { VoteOptions } from './../../types';
import { AdditionalFeedbackField } from './AdditionalFeedbackField';
import { useArticleVisibleFeedbackBi } from './bi';
import css from './index.module.scss';
import { RenderHTML } from '../RenderHTML';

export type VisibleFeedbackProps = {
  articleId: string;
  categoryId: string;
};

export const VisibleFeedback: FunctionComponent<VisibleFeedbackProps> = ({
  articleId,
  categoryId
}: VisibleFeedbackProps) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<VoteOptions>();
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const { experiments } = useExperiments({ suspense: true, readOnly: true });

  const options = [VoteOptions.YES, VoteOptions.UNHELPFUL, VoteOptions.UNSATISFIED, VoteOptions.OTHER];
  const shouldShowAdditionalFeedbackText = selectedOption && selectedOption !== VoteOptions.YES;
  const isArticlePageUXChangesEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_UX_CHANGES);
  const isOtherOptionSelected = selectedOption === VoteOptions.OTHER;
  const sessionStorageKey = `${LOCAL_STORAGE_PREFIX.VISIBLE_FEEDBACK}-${articleId}`;
  const isRedirectFromHelpCenterOnNotHelpedEnabled = experiments.enabled(EXPERIMENTS.SPECS.REDIRECT_FROM_HELP_CENTER_ON_NOT_HELPED);
  const isRedirectFromHelpCenterOnlyBillingPremiumEnabled = experiments.enabled(EXPERIMENTS.SPECS.REDIRECT_FROM_HELP_CENTER_ONLY_BILLING_PREMIUM);
  const setVoteInSessionStorage = (vote: VoteOptions) => setSessionStorage(sessionStorageKey, JSON.stringify(vote));
  const biEvents = useArticleVisibleFeedbackBi(articleId);

  const { itemRef, cancelItemOnPageEvent } = useBiOnVisibleItem<HTMLDivElement>(
    ItemOnPageEventSource.HELPFUL_VOTE_OPTION,
    {
      item_id: articleId,
      feedback_details: BI.FEEDBACK_DETAILS.NEW,
    }
  );

  const getVoteFromSessionStorage = useCallback(() => {
    const vote = sessionStorage.getItem(sessionStorageKey);
    return vote ? JSON.parse(vote) : undefined;
  }, [sessionStorageKey]);

  useEffect(() => {
    const vote = getVoteFromSessionStorage();
    if (vote) {
      setSelectedOption(vote);
      setShowThankYouMessage(true);
      cancelItemOnPageEvent();
    }
  }, [articleId, cancelItemOnPageEvent, getVoteFromSessionStorage]);

  const onBadgeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      if (e.target instanceof HTMLElement) {
        e.target.click();
      }
    }
  };

  const onSendFeedback = (feedbackText: string) => {
    setShowThankYouMessage(true);
    setVoteInSessionStorage(selectedOption as VoteOptions);
    void biEvents.onSendFeedback(t(`visible-feedback.${selectedOption}`), feedbackText);
  };

  const onOptionClick = (option: VoteOptions) => {
    const isPositiveFeedback = option === VoteOptions.YES;
    if (selectedOption !== option) {
      void biEvents.onVoteOptionClick(isPositiveFeedback, t(`visible-feedback.${option}`));
    }
    setSelectedOption(option);
    if (isPositiveFeedback) {
      setShowThankYouMessage(true);
      setVoteInSessionStorage(option);
    } else {
      setShowThankYouMessage(false);
    }
  };

  const isChatbotLinkVisible = useCallback(() => {
    const isBillingOrPremiumArticle = BILLING_AND_PREMIUM_CATEGORIES.includes(categoryId);
    if (isBillingOrPremiumArticle) {
      return (isRedirectFromHelpCenterOnlyBillingPremiumEnabled || isRedirectFromHelpCenterOnNotHelpedEnabled);
    } else {
      return (isRedirectFromHelpCenterOnNotHelpedEnabled && !isRedirectFromHelpCenterOnlyBillingPremiumEnabled);
    }
  },
    [categoryId, isRedirectFromHelpCenterOnNotHelpedEnabled, isRedirectFromHelpCenterOnlyBillingPremiumEnabled]
  );


  const thankYouMessage = useMemo(() => {
    const clickHereElement = `<a target="_blank" href="${CHATBOT_URL + articleId}">
    ${t('visible-feedback.thank-you-unhelpful-chatbot.click-here')}
    </a>`;

    if (selectedOption === VoteOptions.YES) {
      return t('visible-feedback.thank-you-yes');
    } else {
      if (isChatbotLinkVisible()) {
        return t('visible-feedback.thank-you-unhelpful-chatbot', { clickHereElement });
      } else {
        return t('visible-feedback.thank-you');
      }
    }
  }, [articleId, isChatbotLinkVisible, selectedOption]);

  return (
    <Box direction='vertical' className={
      classNames(css.wrapper, {
        [css.sideBar]: isArticlePageUXChangesEnabled,
      })}>
      <Heading className={css.title} size='small'>
        {t('visible-feedback.title')}
      </Heading>

      <Box direction='vertical' className={css.feedbackOptionsContainer} gap='9px'>
        {options.map((option) => (
          <div key={option} tabIndex={-1} onKeyDown={onBadgeKeyDown} onClick={() => setSelectedOption(option)} className={css.feedbackOptionContainer}>
            <Badge className={classNames(css.feedbackOption, { [css.selected]: option === selectedOption })}
              onClick={() => onOptionClick(option)} type={option === selectedOption ? 'solid' : 'outlined'}
              skin='neutralStandard' size='medium' uppercase={false} dataHook={`${DATA_HOOKS.VISIBLE_FEEDBACK_OPTION}-${option} ${option === selectedOption ? DATA_HOOKS.VISIBLE_FEEDBACK_OPTION_SELECTED : ''}`}>
              <Text className={css.feedbackOptionText} size='small' weight='normal'>
                {t(`visible-feedback.${option}`)}
              </Text>
            </Badge>
          </div>
        ))}
      </Box>
      <div ref={itemRef} />
      {!showThankYouMessage && shouldShowAdditionalFeedbackText ? (
        <AdditionalFeedbackField onSendFeedback={onSendFeedback} isOtherOptionSelected={isOtherOptionSelected} />
      ) : null}

      {showThankYouMessage ? (
        <RenderHTML textElement={{
          className: css.thankYouMessage,
          size: 'medium',
          weight: 'normal',
          dataHook: DATA_HOOKS.VISIBLE_FEEDBACK_THANK_YOU_MESSAGE,
        }}
          html={thankYouMessage} />
      ) : null}
    </Box>
  );
};
