import { useRouter } from 'next/router';
import { ArticleResolution, ArticleType } from '@wix/answers-api';
import { Box, Heading, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { TimerSmall } from '@wix/wix-ui-icons-common';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import Anchors, { AnchorsState } from '@wix/cc-user-facing-common-components/Anchors';
import { FunctionComponent, useContext, useMemo } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import { RoadmapResolutionEta } from '../../RoadmapResolutionEta';
import { ArticleStatusBanner } from './ArticleStatusBanner';
import { ArticlePublishedDate } from './ArticlePublishedDate';
import { Step } from '../../../pages/article/[articleTitle]';
import { BI, EXPERIMENTS } from '../../../constants';
import { useBI } from '../../../hooks/useBI';
import { mapArticleTypeToItemType } from '../../../utils/bi';
import { Context } from '../../../context';
import css from './index.module.scss';

export type ArticleHeaderProps = {
  type: ArticleType;
  title: string;
  creationDate: number | Long;
  resolution?: ArticleResolution;
  resolutionEta?: Long;
  isRoadmapArticle?: boolean;
  readingTime?: number;
  onClickStatus?: (resolution: ArticleResolution) => void;
  steps: Step[];
};

const urlsToRunExperimentOn = [
  '/en/article/transferring-your-wix-domain-away-from-wix-2477749',
  '/en/article/wix-domain-assistant-3777632',
  '/en/article/logging-in-to-your-wix-account',
  '/en/article/requesting-a-refund-for-a-premium-plan',
  '/en/article/wix-pay-links-creating-and-managing-pay-links-in-the-wix-app',
  '/en/article/purchasing-a-domain-from-wix-3133977',
  '/en/article/wix-billing-for-premium-plans',
  '/en/article/recovering-your-wix-account',
  '/en/article/spaces-by-wix-editing-your-profile',
  '/en/article/managing-dns-records-in-your-wix-account',
  '/en/article/transferring-a-domain-purchased-elsewhere-to-wix',
  '/en/article/wix-owner-app-deleting-or-restoring-a-site',
  '/en/article/wix-editor-unpublishing-your-site',
  '/en/article/inviting-people-to-contribute-to-your-site',
  '/en/article/managing-invoices-paid-for-wix-services',
  '/en/article/about-the-unsubscribe-link-in-your-email-campaign-2071623',
  '/en/article/connecting-an-email-purchased-outside-of-wix',
  '/en/article/google-workspace-canceling-your-business-email-renewal',
  '/en/article/updating-payment-details-if-renewal-payment-failed-or-is-declined',
  '/en/article/receiving-your-payouts-from-wix-payments',
  '/en/article/business-email-general-troubleshooting',
  '/en/article/checking-unknown-charge-from-wix-by-invoice-number',
  '/en/article/transferring-a-premium-site-to-another-wix-account',
  '/en/article/canceling-and-refunding-orders',
  '/en/article/changing-the-email-address-of-your-wix-account',
  '/en/article/changing-your-wix-account-details',
  '/en/article/refunding-customers-in-wix-payments',
  '/en/article/wix-editor-getting-started-with-the-wix-editor',
  '/en/article/viewing-and-managing-your-site-history',
  '/en/article/chargebacks-in-wix-payments',
  '/en/article/available-payment-providers-in-your-country',
  '/en/article/wix-mobile-apps-customizing-your-site-on-the-member-apps',
  '/en/article/moving-a-site-to-trash',
  '/en/article/wix-forms-choosing-who-gets-notified-about-form-submissions',
  '/en/article/wix-stores-customizing-your-product-page',
  '/en/article/identifying-and-reporting-phishing',
  '/en/article/verifying-your-site-with-the-wix-site-verification-tool'
];

export const ArticleHeader: FunctionComponent<ArticleHeaderProps> = ({
  type,
  resolution,
  resolutionEta,
  title,
  isRoadmapArticle,
  readingTime,
  creationDate,
  onClickStatus,
  steps
}: ArticleHeaderProps) => {
  const { locale, asPath } = useRouter();
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const { pageTemplateData: { itemId } } = useContext(Context);
  const { experiments } = useExperiments({ readOnly: true });
  const anchorsVersion = experiments.get(EXPERIMENTS.SPECS.MOBILE_ANCHORS);

  const mapAnchosVersionToAnchorsState = () => {
    const pathname = asPath.split('?')[0].split('#')[0];
    const localizedPath = `/${locale}${pathname}`;
    if (!urlsToRunExperimentOn.includes(localizedPath)) {
      return AnchorsState.NULL;
    }
    switch (anchorsVersion) {
      case 'A':
        return AnchorsState.SCROLLSPY;
      case 'B':
        return AnchorsState.DROPDOWN;
      case 'C':
      default:
        return AnchorsState.NULL;
    }
  };

  const anchorsState = useMemo(() => mapAnchosVersionToAnchorsState(), []);

  const onAnchorClick = async (text: string, order: string) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ARTICLE,
        kb_lang: locale as string,
        item_id: itemId,
        item_type: mapArticleTypeToItemType(type),
        clicked_item_type: BI.CLICKED_ITEM_TYPES.INTERNAL_ANCHOR,
        clicked_text: text,
        clicked_item_order: order,
        is_page_content_click: true,
      })
    );
  };

  const showReadingTime =
    type === ArticleType.ARTICLE && readingTime && readingTime > 0;

  return (
    <>
      <Heading size='extraLarge' className={css.articleTitle}>
        {title}
      </Heading>

      {type === ArticleType.KNOWN_ISSUE ?
        <ArticlePublishedDate locale={locale as string} creationDate={creationDate} />
        : null}

      {isRoadmapArticle && resolution ? (
        <div className={css.articleRoadmapTags}>
          <RoadmapResolutionEta
            wrapEtaWithTag={true}
            resolution={resolution}
            resolutionEta={resolutionEta}
            onClickStatus={onClickStatus}
          />
        </div>
      ) : (
        [ArticleType.FEATURE_REQUEST, ArticleType.KNOWN_ISSUE].includes(type) &&
        <ArticleStatusBanner
          articleResolution={resolution}
          articleType={type}
        />
      )}
      <Box gap='SP4' direction='vertical' paddingTop='SP3'>
        {showReadingTime ? (
          <Box className={css.timeToReadWrapper}>
            <TimerSmall color={'#868AA5'} />
            <Text
              dataHook={DATA_HOOKS.ARTICLE_READING_TIME}
              size='small'
              color='D40'
              className={css.timeToRead}
            >
              {t('informative-article-time-to-read', { time: readingTime })}
            </Text>
          </Box>
        ) : null}
        {
          anchorsState !== AnchorsState.NULL &&
          steps.length > 0 &&
          <Box className={css.anchorsWrapper}>
            <Anchors steps={steps} onClick={onAnchorClick} state={anchorsState} locale={locale as string} />
          </Box>
        }
      </Box>
    </>
  );
};
