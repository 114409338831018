import { ArticleType } from '@wix/answers-api';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useState } from 'react';
import { BI } from '../../../constants';
import { DATA_HOOKS } from '../../../dataHooks';
import { useBI } from '../../../hooks/useBI';
import { mapArticleTypeToItemType } from '../../../utils/bi';
import { getRedirectToLoginUrl } from '../../../utils/login';
import { stripHTML } from '../../../utils/text';
import { RenderHTML } from '../../RenderHTML';
import css from './index.module.scss';

export type LoginToGetUpdatesProps = {
  type: ArticleType;
  id: string;
};

export const LoginToGetUpdates: FunctionComponent<LoginToGetUpdatesProps> = ({
  id,
  type,
}) => {
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();

  const [loginUrl, setLoginUrl] = useState('');
  const { locale } = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoginUrl(getRedirectToLoginUrl(locale as string, true));
    }
  }, [locale]);

  const onClickListener = async (e: React.MouseEvent<HTMLLinkElement>) => {
    const target = e.target as HTMLLinkElement;
    if (target.tagName && target.tagName === 'A') {
      await sendBIEvent(
        pageClick({
          source_name: BI.SOURCE_NAMES.ARTICLE,
          kb_lang: locale as string,
          item_id: id,
          item_type: mapArticleTypeToItemType(type),
          clicked_item_type: BI.CLICKED_ITEM_TYPES.CTA,
          is_page_content_click: false,
          clicked_text: stripHTML(
            t('follow-button.login-to-get-update', { loginUrl })
          ),
          clicked_url: target.href,
        })
      );
    }
  };

  return (
    <RenderHTML
      className={css.loginToGetUpdates}
      html={t(`follow-button.${type}.login-to-get-update`, { loginUrl })}
      textElement={{
        size: 'small',
        weight: 'thin',
        dataHook: DATA_HOOKS.LOGIN_TO_GET_UPDATES,
        onClick: onClickListener,
      }}
    />
  );
};
