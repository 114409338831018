import { useEffect, useState, useRef, RefObject } from 'react';

export const useOnVisible = (ref: RefObject<HTMLElement>) => {
  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsVisible(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    if (observerRef && ref && ref.current) {
      observerRef.current?.observe(ref.current as HTMLElement);

      return () => {
        observerRef.current?.disconnect();
      };
    }
  }, [ref]);

  return isVisible;
};
