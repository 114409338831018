/* eslint-disable max-lines */
import {
  ArticleAdditionalFeedbackTypeNegative,
  ArticleAdditionalFeedbackTypePositive,
  ArticleHelpfulState,
  ArticleType,
  SourceType,
} from '@wix/answers-api';
import {
  clickTheWasItHelpfulButtons,
  submitWasItHelpfulForm,
} from '@wix/bi-logger-new-help-center/v2';
import { Box, Heading } from '@wix/design-system';
import { useExperiments, useFedops } from '@wix/fe-essentials-standalone';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  BI,
  BILLING_AND_PREMIUM_CATEGORIES,
  CHATBOT_URL,
  EXPERIMENTS,
  FEDOPS,
  LOCAL_STORAGE_PREFIX,
} from '../../constants';
import { Context } from '../../context';
import { DATA_HOOKS } from '../../dataHooks';
import { useBI } from '../../hooks/useBI';
import { mapArticleTypeToItemType } from '../../utils/bi';
import { RenderHTML } from '../RenderHTML';
import { AdditionalFeedback } from './AdditionalFeedback';
import css from './index.module.scss';
import {
  getSessionStorage,
  setSessionStorage,
} from '../../utils/webStorageWrapper';
import {
  ItemOnPageEventSource,
  useBiOnVisibleItem,
} from '../../hooks/useBiOnVisibleItem';
import { VotingVariationA } from './VotingVartiationA';
import classNames from 'classnames';
import { useOutsideClick } from '../../hooks/useOutsideClick';

export type ArticleFeedbackProps = {
  articleId: string;
  categoryId: string;
};

export type AdditionalOption =
  | ArticleAdditionalFeedbackTypeNegative
  | ArticleAdditionalFeedbackTypePositive;

export const ArticleFeedback: FunctionComponent<ArticleFeedbackProps> = ({
  articleId,
  categoryId,
}: ArticleFeedbackProps) => {
  const [vote, setVote] = useState<ArticleHelpfulState>(
    ArticleHelpfulState.NONE
  );
  const { sendBIEvent } = useBI();
  const [additionalOption, setAdditionalOption] = useState<
    AdditionalOption | undefined
  >(undefined);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const ref = useOutsideClick(() => setIsSticky(false));
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [isChatbotLinkVisible, setIsChatbotLinkVisible] =
    useState<boolean>(false);
  const { experiments } = useExperiments({ suspense: true, readOnly: true });
  const { t } = useTranslation();
  const { answersApi } = useContext(Context);
  const fedopsApi = useFedops();
  const { locale } = useRouter();
  const SECONDS_THANK_YOU_DISPLAYED = 5000;
  const isArticlePageUXChangesEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_UX_CHANGES);

  const feedbackDetails = BI.FEEDBACK_DETAILS.OLD;

  const { itemRef, cancelItemOnPageEvent } = useBiOnVisibleItem<HTMLDivElement>(
    ItemOnPageEventSource.HELPFUL_VOTE_OPTION,
    {
      item_id: articleId,
      feedback_details: feedbackDetails,
    }
  );

  const getVoteFromSessionStorage = () => {
    const voteFromSessionStorage = getSessionStorage(
      `${LOCAL_STORAGE_PREFIX.FEEDBACK_VOTE}${articleId}`
    );
    if (voteFromSessionStorage) {
      const storedVote = JSON.parse(voteFromSessionStorage)
        ? ArticleHelpfulState.HELPFUL
        : ArticleHelpfulState.NOT_HELPFUL;
      setVote(storedVote);
      setAdditionalOption(
        storedVote === ArticleHelpfulState.HELPFUL
          ? ArticleAdditionalFeedbackTypePositive.HELPFUL
          : undefined
      );
      setIsSticky(true);
    } else {
      setVote(ArticleHelpfulState.NONE);
    }
  };

  const getFeedbackFromSessionStorage = () => {
    const feedbackOptionFromSessionStorage = getSessionStorage(
      `${LOCAL_STORAGE_PREFIX.ADDITIONAL_FEEDBACK_OPTION}${articleId}`
    );

    if (feedbackOptionFromSessionStorage) {
      setIsSticky(false);
      setSubmitted(true);
      setShowThankYou(true);
      cancelItemOnPageEvent();
    }
  };

  useEffect(() => {
    setShowThankYou(false);
    setSubmitted(false);
    getVoteFromSessionStorage();
    getFeedbackFromSessionStorage();
  }, [articleId]);

  const onVote = async (submittedVote: ArticleHelpfulState) => {
    setVote(submittedVote);
    setAdditionalOption(
      submittedVote === ArticleHelpfulState.HELPFUL
        ? ArticleAdditionalFeedbackTypePositive.HELPFUL
        : undefined
    );
    setIsSticky(true);
    fedopsApi.interactionStarted(FEDOPS.INTERACTION_NAMES.HELPFUL_ARTICLE);

    await answersApi.articles.sendFeedback({
      sourceType: SourceType.HELP_CENTER,
      sourceId: null,
      id: articleId,
      newState: submittedVote,
      locale: locale as string,
    });

    fedopsApi.interactionEnded(FEDOPS.INTERACTION_NAMES.HELPFUL_ARTICLE);

    if (submittedVote !== vote) {
      await sendBIEvent(
        clickTheWasItHelpfulButtons({
          source_name: BI.SOURCE_NAMES.ARTICLE,
          kb_lang: locale as string,
          item_id: articleId,
          item_type: mapArticleTypeToItemType(ArticleType.ARTICLE),
          thumbs_up: submittedVote === ArticleHelpfulState.HELPFUL,
          feedback_details: feedbackDetails,
        })
      );
    }

    const voteToSessionStorage =
      submittedVote === ArticleHelpfulState.NOT_HELPFUL
        ? String(false)
        : String(true);
    setSessionStorage(
      `${LOCAL_STORAGE_PREFIX.FEEDBACK_VOTE}${articleId}`,
      voteToSessionStorage
    );
  };

  const submitBiEvent = async (
    feedbackText: string,
    unhelpfulOption:
      | ArticleAdditionalFeedbackTypeNegative
      | ArticleAdditionalFeedbackTypePositive
  ) => {
    const feedbackTypeToOrder = (type: ArticleAdditionalFeedbackTypeNegative) =>
      ((type - 10) / 10 - 10 + 1).toString();
    const unhelpfulOptions = Object.values(
      ArticleAdditionalFeedbackTypeNegative
    ).filter((v) => !isNaN(Number(v)));

    const unhelpfulOptionsContentMap = unhelpfulOptions.map(
      (unhelpfulOptionItem, index) => ({
        answer_text: t(
          `article-feedback.additional.unhelpful.option.${unhelpfulOptionItem}`
        ),
        answer_order: index + 1,
        language: locale,
      })
    );

    const biData =
      unhelpfulOption === ArticleAdditionalFeedbackTypePositive.HELPFUL
        ? {
          source_name: BI.SOURCE_NAMES.ARTICLE,
          item_id: articleId,
          thumbs_up: vote === ArticleHelpfulState.HELPFUL,
          free_text: feedbackText.trim(),
          feedback_details: feedbackDetails,
          kb_lang: locale,
        }
        : {
          clicked_item_order: feedbackTypeToOrder(unhelpfulOption),
          clicked_item_index: unhelpfulOption,
          clicked_text: t(
            `article-feedback.additional.unhelpful.option.${unhelpfulOption}`
          ),
          free_text: feedbackText.trim(),
          item_id: articleId,
          kb_lang: locale,
          presented_content: JSON.stringify(unhelpfulOptionsContentMap),
          source_name: BI.SOURCE_NAMES.ARTICLE,
          thumbs_up: vote === ArticleHelpfulState.HELPFUL,
          feedback_details: feedbackDetails,
        };

    await sendBIEvent(submitWasItHelpfulForm(biData));
  };

  const onSubmit = async (feedbackText: string) => {
    setSubmitted(true);
    if (additionalOption !== undefined) {
      const isPositiveAdditionalTextEmpty =
        additionalOption === ArticleAdditionalFeedbackTypePositive.HELPFUL &&
        feedbackText.trim().length === 0;

      if (!isPositiveAdditionalTextEmpty) {
        fedopsApi.interactionStarted(
          FEDOPS.INTERACTION_NAMES.ADDITIONAL_FEEDBACK
        );

        await answersApi.articles.sendAdditionalFeedback({
          article_id: articleId,
          locale: locale as string,
          comment: feedbackText.trim(),
          feedbackOption: additionalOption,
        });

        fedopsApi.interactionEnded(
          FEDOPS.INTERACTION_NAMES.ADDITIONAL_FEEDBACK
        );
      }
      setShowThankYou(true);
      setTimeout(() => {
        setIsSticky(false);
      }, SECONDS_THANK_YOU_DISPLAYED);

      await submitBiEvent(feedbackText, additionalOption);
      saveToSessionStorage(additionalOption);
    }
  };

  const saveToSessionStorage = (unhelpfulOption: AdditionalOption) => {
    setSessionStorage(
      `${LOCAL_STORAGE_PREFIX.ADDITIONAL_FEEDBACK_OPTION}${articleId}`,
      String(unhelpfulOption)
    );
  };

  const clickHereElement = `<a target="_blank" href="${CHATBOT_URL + articleId
    }">${t(
      'article-feedback.message.thank-you-unhelpful-chatbot.click-here'
    )}</a>`;

  const calcIsVisibleLinkToChatbot = useCallback(
    (
      isChatbotLinkGlobalVisible: boolean,
      isVisibleOnlyForBillingPremium: boolean
    ) => {
      const isBillingOrPremiumArticle =
        BILLING_AND_PREMIUM_CATEGORIES.includes(categoryId);

      if (isBillingOrPremiumArticle) {
        setIsChatbotLinkVisible(
          isVisibleOnlyForBillingPremium || isChatbotLinkGlobalVisible
        );
      } else {
        setIsChatbotLinkVisible(
          isChatbotLinkGlobalVisible && !isVisibleOnlyForBillingPremium
        );
      }
    },
    [categoryId]
  );

  useEffect(() => {
    calcIsVisibleLinkToChatbot(
      experiments.enabled(
        EXPERIMENTS.SPECS.REDIRECT_FROM_HELP_CENTER_ON_NOT_HELPED
      ),
      experiments.enabled(
        EXPERIMENTS.SPECS.REDIRECT_FROM_HELP_CENTER_ONLY_BILLING_PREMIUM
      )
    );
  }, [experiments, calcIsVisibleLinkToChatbot]);

  const thankYouMessage = () =>
    vote === ArticleHelpfulState.HELPFUL
      ? t('article-feedback.message.thank-you-helpful')
      : isChatbotLinkVisible
        ? t('article-feedback.message.thank-you-unhelpful-chatbot', {
          clickHereElement,
        })
        : t('article-feedback.message.thank-you-unhelpful');

  const hideSubmittedSectionAfterRefresh = submitted && !showThankYou;
  return (
    <Box
      direction="horizontal"
      width="100%"
      align="center"
      dataHook={DATA_HOOKS.ARTICLE_FEEDBACK}
      className={classNames({
        [css.wrapper]: !isArticlePageUXChangesEnabled,
        [css.newWrapper]: isArticlePageUXChangesEnabled,
        [css.sticky]: !isArticlePageUXChangesEnabled && isSticky,
      })}
    >
      <Box className={css.articleFeedback} width="100%" ref={ref}>
        {!hideSubmittedSectionAfterRefresh && !submitted ? (
          <Box className={css.InnerVote} direction="vertical" width="100%">
            <Box
              direction="vertical"
              width="100%"
              gap="12px"
              className={css.innerVoteWrapper}
            >
              <Heading
                size="small"
                className={css.title}
                dataHook={DATA_HOOKS.ARTICLE_FEEDBACK_TITLE}
              >
                {t('helpful.title')}
              </Heading>
              <Box className={css.votingFeedback} direction="vertical">
                <VotingVariationA vote={vote} onVote={onVote} />
                <div ref={itemRef} />
              </Box>
            </Box>
            {vote === ArticleHelpfulState.NONE ? null : (
              <AdditionalFeedback
                voteState={vote}
                additionalOption={additionalOption}
                onSubmit={onSubmit}
                onChangeAdditionalOption={setAdditionalOption}
              />
            )}
          </Box>
        ) : (
          showThankYou && (
            <Box
              className={css.thankYouMessage}
              dataHook={DATA_HOOKS.THANKS_MESSAGE}
              width="100%"
            >
              <RenderHTML
                html={thankYouMessage()}
                dataHook={DATA_HOOKS.HELPFUL_MESSAGE}
              />
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};
