import { Article } from '@wix/answers-api';
import {
  ArticleSearchResultDocumentType,
  ArticleSearchResultItem,
} from '../../types';

export const normalizeArticle = (article: Article): ArticleSearchResultItem => {
  const {
    id,
    title,
    content,
    uri,
    url,
    type,
    categoryId,
    resolution,
    resolutionEta,
    labels = [],
    readingTimeInMinutes,
    featureEnrollment,
    creationDate,
    allowEnrollment,
  } = article as unknown as Article;
  return {
    allowEnrollment,
    id,
    title,
    description: content,
    uri,
    url,
    type,
    categoryId,
    labels,
    resolution: resolution || null,
    resolutionEta: resolutionEta ? Number(resolutionEta) : null,
    readingTimeInMinutes,
    featureEnrollment,
    creationDate,
    docType: ArticleSearchResultDocumentType.ANSWERS_ARTICLE,
  };
};
