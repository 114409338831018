import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { ArticleViewer } from '@wix/answers-article-viewer';
import { deserialize } from '@wix/answers-article-html-serdes';
import { Box } from '@wix/design-system';
import { useRouter } from 'next/router';
import { Article } from '@wix/answers-api';
import cheerio from 'cheerio';
import { isMobile } from '../../../utils/userAgent';
import { BI } from '../../../constants';
import { useBI } from '../../../hooks/useBI';
import css from './index.module.scss';
import useYouTubePlayers from '../../../hooks/useYouTubePlayers';
import { useEditorXNotificationHeight } from '../../../hooks/useEditorXNotificationHeight';
import { useArticleContentBi } from '../bi';

type ArticleViewerWrapperProps = {
  article: Article;
  showEditorXFloatingNotification?: boolean;
  onLinkClick: (e: MouseEvent, href: string) => void;
  onTabClick: (selectedTab: number, currentTabSlugs: string[]) => void;
  maybeGetSelectedTabsFromURL: () => string[];
};

export const ArticleViewerWrapper: FunctionComponent<ArticleViewerWrapperProps> = ({
  article,
  showEditorXFloatingNotification,
  onLinkClick,
  onTabClick,
  maybeGetSelectedTabsFromURL
}: ArticleViewerWrapperProps) => {
  const {
    id: articleId,
    type,
    content
  } = article;
  const { locale } = useRouter();
  const containerRef = useRef<HTMLDivElement>(null);
  useYouTubePlayers(containerRef, articleId);

  const { onImageClick, onTabHeadingClickBi, sectionInteractionBi } = useArticleContentBi(articleId, type);
  const { sendBIEvent } = useBI();

  const deserializedContent = useMemo(
    () => deserialize(content, locale as string, cheerio),
    [content, locale]
  );

  const [editorXNotificationHeight] = useEditorXNotificationHeight();


  useEffect(() => {
    const handleBIEvents = () => {

      const collapsibleComponents = document.querySelectorAll(
        BI.SELECTORS.COLLAPSIBLE_COMPONENTS
      );

      const imagesComponents = document.querySelectorAll(
        BI.SELECTORS.CLICKABLE_IMAGES
      );

      const tabsComponentsHeadings = document.querySelectorAll(
        BI.SELECTORS.TABS
      );

      tabsComponentsHeadings.forEach((heading) => {
        heading.addEventListener('click', onTabHeadingClickBi);
      });

      imagesComponents.forEach((img) =>
        img.addEventListener('click', onImageClick)
      );

      if (collapsibleComponents) {
        collapsibleComponents.forEach((collapsibleComponent) => {
          collapsibleComponent.addEventListener('click', sectionInteractionBi);
        });
      }

      return () => {
        if (collapsibleComponents) {
          collapsibleComponents.forEach((collapsibleComponent) => {
            collapsibleComponent.removeEventListener(
              'click',
              sectionInteractionBi
            );
          });
        }
        if (imagesComponents) {
          imagesComponents.forEach((imagComp) => {
            imagComp.removeEventListener('click', onImageClick);
          });
        }
        if (tabsComponentsHeadings) {
          tabsComponentsHeadings.forEach((tabComp) => {
            tabComp.removeEventListener('click', onTabHeadingClickBi);
          });
        }
      };
    };

    return handleBIEvents();
  }, [articleId, locale, type, sendBIEvent, onImageClick]);

  const articleViewerMemo = (
    <ArticleViewer
      key={articleId}
      components={deserializedContent}
      locale={locale as string}
      onLinkClick={onLinkClick}
      tabsProps={{
        onTabClick,
        selectedTabsOnLoad: maybeGetSelectedTabsFromURL(),
      }}
      theme="helpcenter"
    />
  );

  const notificationHeight = editorXNotificationHeight + 10;
  return (<div ref={containerRef}>
    {showEditorXFloatingNotification && isMobile() ? (
      <Box
        marginTop={`${notificationHeight}px`}
        className={css.mobileArticleViewerWrapper}
      >
        {articleViewerMemo}
      </Box>
    ) : articleViewerMemo
    }
  </div>);
};
