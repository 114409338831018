import { wasItHelpfulView as wasVideoIframeViewed } from '@wix/bi-logger-new-help-center/v2';
import { useRouter } from 'next/router';
import { RefObject, useEffect, useCallback, useRef, useState } from 'react';
import { BI } from '../constants';
import { CustomDocument, CustomWindow, YouTubeTypeEvent } from '../types';
import { useBI } from './useBI';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';

// YouTube Player API script URL
// const API_SCRIPT_URL = 'https://www.youtube.com/iframe_api'
declare const window: CustomWindow;
declare const document: CustomDocument;

function useYouTubePlayers(
  containerRef: RefObject<HTMLDivElement>,
  articleId: string
): void {
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();
  const [isFullscreen, setIsFullscreenOpened] = useState<boolean>(false);
  const [wasBiEventSent, setWasBiEventSent] = useState<Record<string, boolean>>({});

  const myFullScreenState = useRef(isFullscreen);
  const setMyFullScreenState = (data: boolean) => {
    myFullScreenState.current = data;
    setIsFullscreenOpened(data);
  };

  const sendHalfVisibleEvent = useCallback(async () => {
    await sendBIEvent(
      wasVideoIframeViewed({
        item_id: articleId,
        item_name: BI.ITEM_NAMES.YOUTUBE_VIDEO,
        kb_lang: locale,
        source_name: BI.SOURCE_NAMES.ARTICLE,
      })
    );
  }, [articleId, locale]);

  useEffect(() => {
    const containerElement = containerRef.current;
    const allYouTubeIframes = containerElement?.querySelectorAll(
      '.component.video-component iframe'
    );

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          const { target, isIntersecting } = entry;
          const id = target.getAttribute('id');
          if (id && isIntersecting && !wasBiEventSent[id]) {
            setWasBiEventSent((prev) => ({ ...prev, [id]: true }));
            await sendHalfVisibleEvent();
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    allYouTubeIframes?.forEach((iframe) => {
      observer.observe(iframe);
    });

    return () => {
      observer.disconnect();
    };
  }, [containerRef, sendHalfVisibleEvent, wasBiEventSent]);

  useEffect(() => {
    const containerElement = containerRef.current;

    if (!containerElement) {
      return;
    }

    const iframs = containerElement.querySelectorAll(
      '.component.video-component iframe'
    );

    if (!iframs || iframs.length === 0) {
      return;
    }

    const iframsIds: { id: string; src: string }[] = [];

    iframs.forEach((elm) => {
      const src = elm.getAttribute('src');
      if (src) {
        const id = extractVideoIdFromUrl(src);
        if (id) {
          elm.setAttribute('id', id);
          iframsIds.push({ id, src });
        }
      }
    });

    const tag = document.createElement('script');
    tag.id = 'iframe_api';
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag && firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    tag.onload = () => {
      if (window.YT) {
        window.YT.ready(() => createEventsListener());
      }
    };

    function createEventsListener() {
      iframsIds.forEach(({ id, src }) => {
        if (window.YT) {
          const obj = new window.YT.Player(id, {
            events: {
              onStateChange: async (event: any) => {
                const { data: type } = event;

                switch (type) {
                  case 1:
                    await fireClickEvent(YouTubeTypeEvent.PLAY_VIDEO);
                    break;
                  case 2:
                    await fireClickEvent(YouTubeTypeEvent.PAUSE_VIDEO);
                    break;
                  default:
                    break;
                }

                async function fireClickEvent(eventType: YouTubeTypeEvent) {
                  await sendBIEvent(
                    pageClick({
                      source_name: BI.SOURCE_NAMES.ARTICLE,
                      kb_lang: locale as string,
                      item_id: articleId,
                      item_type: BI.ITEM_TYPES.VIDEO,
                      clicked_item_type: BI.CLICKED_ITEM_TYPES.VIDEO,
                      clicked_text: eventType,
                      clicked_url: src,
                    })
                  );
                }
              },
            },
          });
          return obj;
        }
      });
    }

    const sendFullScreenEvent = async () => {
      const elm = getElemVideoInFullscreen();
      const isCuurentFullScreenOpen = myFullScreenState.current;
      if (elm) {
        await sendBIEvent(
          pageClick({
            source_name: BI.SOURCE_NAMES.ARTICLE,
            kb_lang: locale as string,
            item_id: articleId,
            item_type: BI.ITEM_TYPES.VIDEO,
            clicked_item_type: BI.CLICKED_ITEM_TYPES.VIDEO,
            clicked_text: YouTubeTypeEvent.OPEN_VIDEO_FULL_SCREEN,
            clicked_url: elm.getAttribute('src') || '',
          })
        );
        setMyFullScreenState(true);
      } else if (isCuurentFullScreenOpen) {
        await sendBIEvent(
          pageClick({
            source_name: BI.SOURCE_NAMES.ARTICLE,
            kb_lang: locale as string,
            item_id: articleId,
            item_type: BI.ITEM_TYPES.VIDEO,
            clicked_item_type: BI.CLICKED_ITEM_TYPES.VIDEO,
            clicked_text: YouTubeTypeEvent.CLOSE_VIDEO_FULL_SCREEN,
          })
        );
        setMyFullScreenState(false);
      }
    };
    window.addEventListener('fullscreenchange', sendFullScreenEvent);

    function getElemVideoInFullscreen(): Element | null {
      const fullscreenElement =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;

      if (fullscreenElement && fullscreenElement.tagName === 'IFRAME') {
        const idElm = fullscreenElement.getAttribute('id');
        if (iframsIds.some(({ id: iframeId }) => iframeId === idElm)) {
          return fullscreenElement;
        }
        return null;
      }

      return null;
    }

    return () => {
      window.removeEventListener('fullscreenchange', sendFullScreenEvent);
    };
  }, []);
}

export default useYouTubePlayers;

function extractVideoIdFromUrl(url: string | null) {
  if (!url) {
    return null;
  }

  const videoIdPattern =
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?|watch)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\r\n]{11})/;

  // Attempt to match the pattern in the URL
  const match = url.match(videoIdPattern);

  // If a match is found, return the video ID (group 1 of the match)
  if (match) {
    return match[1];
  }

  // If no match is found, return null
  return null;
}
