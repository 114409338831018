import { ArticleResolution, ArticleType } from '@wix/answers-api';
import { Box, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../dataHooks';
import { resolutionTransformLocalize } from '../../utils/roadmapLocalizationKit';
import css from './index.module.scss';

export type CtaEngagementTextProps = {
  articleResolution: ArticleResolution | null;
  articleType: ArticleType;
};

export const CtaEngagementText: FunctionComponent<CtaEngagementTextProps> = ({
  articleResolution,
  articleType,
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const getText = () => {
    const resolution = resolutionTransformLocalize(articleResolution!, locale);

    const knownIssuesTexts: Partial<Record<ArticleResolution, string>> = {
      [ArticleResolution.RESOLVED]: t('cta-engagement.resolved.known-issue'),
    };

    const featureRequestTexts: Partial<Record<ArticleResolution, string>> = {
      [ArticleResolution.COLLECTING_VOTES]: t('cta-engagement.vote-for.feature-request'),
    };

    switch (articleType) {
      case ArticleType.FEATURE_REQUEST:
        return featureRequestTexts[resolution] || t('cta-engagement.forthcoming.feature-request');
      case ArticleType.KNOWN_ISSUE:
        return knownIssuesTexts[resolution] || t('cta-engagement.investigating.known-issue');
      default:
        return '';
    }
  };

  if (!articleResolution
    || articleType === ArticleType.ARTICLE
    || (articleType === ArticleType.FEATURE_REQUEST && [ArticleResolution.RELEASED, ArticleResolution.RESOLVED].includes(articleResolution))) {
      return null;
    }

  return (
    <Box
      direction="vertical"
      className={css.CtaEngagementTextWrapper}
      dataHook={DATA_HOOKS.CTA_ENGAGEMENT_TEXT_WRAPPER}
    >
      <Text
        size="medium"
        weight="thin"
        dataHook={DATA_HOOKS.CTA_ENGAGEMENT_TEXT}
      >
        {getText()}
      </Text>
    </Box>
  );
};
