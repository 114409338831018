import { useRouter } from 'next/router';
import { useBI } from '../../hooks/useBI';
import { useCallback } from 'react';
import { BI } from '../../constants';
import { mapArticleTypeToItemType } from '../../utils/bi';
import { ArticleType } from '@wix/answers-api';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';

export const useArticleContentBi = (articleId: string, type: ArticleType) => {
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();

  const commonProps = {
    source_name: BI.SOURCE_NAMES.ARTICLE,
    kb_lang: locale as string,
    item_id: articleId,
    item_type: mapArticleTypeToItemType(type),
    is_page_content_click: true,
  };

  const onImageClick = useCallback(
    async (e: Event) => {
      const imgSrc =
        (e?.target as HTMLElement)?.getAttribute('src') || undefined;
      await sendBIEvent(
        pageClick({
          ...commonProps,
          clicked_item_type: BI.CLICKED_ITEM_TYPES.PICTURE,
          clicked_url: imgSrc,
        })
      );
    },
    [articleId, locale, sendBIEvent, type]
  );

  const onLinkClickBi = useCallback(
    async (href: string, clickedItemType: string, text: string, clickedItemOrder?: string) => {
      await sendBIEvent(
        pageClick({
          ...commonProps,
          clicked_item_type: clickedItemType,
          clicked_url: href,
          clicked_text: text,
          clicked_item_order: clickedItemOrder,
        })
      );
    }, [articleId, locale, sendBIEvent, type]);


  const onTabHeadingClickBi = useCallback(
    async (e: Event) => {
      const element = e?.target as HTMLElement;
      const tabHeading = element?.textContent || undefined;

      const wasNotSelectedBefore =
        !element.className.includes('selected') &&
        !element.parentElement?.className.includes('selected');
      if (wasNotSelectedBefore) {
        await sendBIEvent(
          pageClick({
            ...commonProps,
            clicked_item_type: BI.CLICKED_ITEM_TYPES.TAB_SELECTION,
            clicked_text: tabHeading,
          })
        );
      }
    },
    [articleId, locale, sendBIEvent, type]
  );

  const sectionInteractionBi = async (e: Event): Promise<void> => {
    const text = (e.target as HTMLElement)?.textContent;
    const isSectionExpand =
      (e.target as HTMLDivElement)?.getAttribute('aria-expanded') ===
      'false' ||
      (e.target as HTMLDivElement)?.parentElement?.getAttribute(
        'aria-expanded'
      ) === 'false';

    await sendBIEvent(
      pageClick({
        ...commonProps,
        clicked_item_type: isSectionExpand
          ? BI.CLICKED_ITEM_TYPES.SECTION_EXPAND
          : BI.CLICKED_ITEM_TYPES.SECTION_COLLAPSE,
        clicked_text: text as string,
      })
    );
  };

  return { onImageClick, onTabHeadingClickBi, sectionInteractionBi, onLinkClickBi };

};
