import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { Box, Heading, Text, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { ExternalLink as ExternalLinkIcon } from 'wix-ui-icons-common';
import { BI, WIX_STUDIO_COMMUNITY_FORUM_LINK, WIX_STUDIO_SUPPORTED_LANGUAGES } from '../../../constants';
import { DATA_HOOKS } from '../../../dataHooks';
import { useBI } from '../../../hooks/useBI';
import css from './index.module.scss';
import { useRouter } from 'next/router';
import { useBiOnVisibleItem, ItemOnPageEventSource } from '../../../hooks/useBiOnVisibleItem';
import { ArticleType } from '@wix/answers-api';
import { mapArticleTypeToItemType } from '../../../utils/bi';

enum EntryBannerType {
  STUDIO_WISHLIST_ENTRY = 'STUDIO_WISHLIST_ENTRY',
  FORUM_ENTRY = 'FORUM_ENTRY',
}

export type StudioEntryBannerProps = {
  articleId: string;
  articleType: ArticleType;
};

const STUDIO_ENTRY_URL_MAP = {
  [EntryBannerType.STUDIO_WISHLIST_ENTRY]: WIX_STUDIO_COMMUNITY_FORUM_LINK,
  [EntryBannerType.FORUM_ENTRY]:  WIX_STUDIO_COMMUNITY_FORUM_LINK,
};

const TRANSLATION_KEY_PREFIX_MAP = {
  [EntryBannerType.STUDIO_WISHLIST_ENTRY]:
    'feature-request-studio-forum-entry',
  [EntryBannerType.FORUM_ENTRY]: 'studio-forum-banner',
};

const ITEM_ON_PAGE_SOURCE_MAP = {
  [EntryBannerType.STUDIO_WISHLIST_ENTRY]:
    ItemOnPageEventSource.STUDIO_COMMUNITY_WISHLIST_ENTRY,
  [EntryBannerType.FORUM_ENTRY]: ItemOnPageEventSource.STUDIO_COMMUNITY_FORUM_ENTRY_ARTICLE,
};

export const StudioEntryBanner: FunctionComponent<
  StudioEntryBannerProps
> = ({ articleId, articleType }) => {

  const type = articleType === ArticleType.FEATURE_REQUEST ?
    EntryBannerType.STUDIO_WISHLIST_ENTRY
    : EntryBannerType.FORUM_ENTRY;

  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const { itemRef } = useBiOnVisibleItem(ITEM_ON_PAGE_SOURCE_MAP[type], { item_id: articleId });

  const wixStudioEntryUrl = STUDIO_ENTRY_URL_MAP[type];

  const texts = useMemo(() => {
    const isWishlist = type === EntryBannerType.STUDIO_WISHLIST_ENTRY;
    return {
      title: t(`${TRANSLATION_KEY_PREFIX_MAP[type]}.title`),
      description: t(`${TRANSLATION_KEY_PREFIX_MAP[type]}.${isWishlist ? 'description' : 'subtitle'}`),
      ctaText: t(`${TRANSLATION_KEY_PREFIX_MAP[type]}.${isWishlist ? 'cta-text' : 'cta'}`)
    };
  }, [type, t]);

  const { title, description, ctaText } = texts;

  const onClick = useCallback(async () => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ARTICLE,
        kb_lang: locale as string,
        item_id: articleId,
        is_page_content_click: false,
        item_type: mapArticleTypeToItemType(articleType),
        clicked_item_type: BI.CLICKED_ITEM_TYPES.WIX_STUDIO_COMMUNITY_FORUM,
        clicked_url: wixStudioEntryUrl,
        clicked_text: ctaText
      })
    );
  }, [articleId, ctaText, locale, wixStudioEntryUrl]);

  if (!WIX_STUDIO_SUPPORTED_LANGUAGES.includes(locale as string)) {
    return null;
  }

  return (
    <Box
      className={css.wrapper}
      direction="vertical"
      gap="SP3"
      dataHook={DATA_HOOKS.STUDIO_ENTRY_POINT_BANNER}
    >
      <Box direction="vertical" gap="SP2">
        <Heading
          className={css.title}
          dataHook={DATA_HOOKS.STUDIO_ENTRY_POINT_BANNER_TITLE}
          size="medium"
        >
          {title}
        </Heading>
        <Text
          dataHook={DATA_HOOKS.STUDIO_ENTRY_POINT_BANNER_DESCRIPTION}
          size="small"
        >
          {description}
        </Text>
      </Box>
      <Box ref={itemRef}>
        <TextButton
          onClick={onClick}
          as="a"
          href={wixStudioEntryUrl}
          target="_blank"
          size="small"
          className={css.cta}
          underline="always"
          dataHook={DATA_HOOKS.STUDIO_ENTRY_POINT_BANNER_CTA}
          suffixIcon={<ExternalLinkIcon />}
        >
          {ctaText}
        </TextButton>
      </Box>
    </Box>
  );
};
