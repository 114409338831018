import { useRouter } from 'next/router';
import { useTranslation } from '@wix/wix-i18n-config';
import React, { FunctionComponent, useEffect } from 'react';
import { Box, Button, Heading, Text } from '@wix/design-system';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { DATA_HOOKS } from '../../../dataHooks';
import { getContent } from './contentLogic';
import { useBI } from '../../../hooks/useBI';
import { BI } from '../../../constants';
import { RenderHTML } from '../../RenderHTML';
import css from './index.module.scss';
import { ItemOnPageEventSource, useBiOnVisibleItem } from '../../../hooks/useBiOnVisibleItem';

type HirePartnerProps = {
  articleId: string;
  categoryId: string;
};

export const HirePartner: FunctionComponent<HirePartnerProps> = ({
  articleId,
  categoryId,
}: HirePartnerProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const { itemRef, resetItemOnPageEvent } = useBiOnVisibleItem(ItemOnPageEventSource.HIRE_A_PARTNER,
    {
      item_id: articleId,
    });

  const content = getContent(articleId, categoryId, locale as string);
  useEffect(() => {
    resetItemOnPageEvent();
  }, [articleId]);

  return content !== null ? (
    <Box dataHook={DATA_HOOKS.HIRE_PARTNER} className={css.wrapper}>
      <Box direction="vertical" className={css.HirePartnerBox}>
        <Heading
          size="medium"
          as="h2"
          className={css.title}
          dataHook={DATA_HOOKS.HIRE_PARTNER_TITLE}
        >
          {t(content.texts.title)}
        </Heading>
        <Text>
          <RenderHTML
            html={t(content.texts.description)}
            className={css.description}
            dataHook={DATA_HOOKS.HIRE_PARTNER_DESCRIPTION}
          />
        </Text>
        <Button
          dataHook={DATA_HOOKS.HIRE_PARTNER_CTA}
          target="_blank"
          className={css.cta}
          as="a"
          type="button"
          priority="secondary"
          href={content.link}
          size="tiny"
          onClick={async () =>
            sendBIEvent(
              pageClick({
                source_name: BI.SOURCE_NAMES.ARTICLE,
                kb_lang: locale as string,
                item_id: articleId,
                clicked_item_type: BI.CLICKED_ITEM_TYPES.HIRE_A_WIX_PARTNER,
                clicked_url: content.link,
                clicked_text: t(content.texts.cta),
                is_page_content_click: false,
              })
            )
          }
        >
          {t(content.texts.cta)}
        </Button>
        <div ref={itemRef} />
      </Box>
    </Box>
  ) : null;
};
