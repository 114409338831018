import { Box, Button, InputArea, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent, useMemo, useState } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import css from './index.module.scss';

export type AdditionalFeedbackFieldProps = {
  isOtherOptionSelected: boolean;
  onSendFeedback: (feedbackText: string) => void;
};

const MAX_ADDITIONAL_TEXT_LENGTH = 2000;

export const AdditionalFeedbackField: FunctionComponent<AdditionalFeedbackFieldProps> = ({
  isOtherOptionSelected,
  onSendFeedback,
}: AdditionalFeedbackFieldProps) => {
  const { t } = useTranslation();
  const [feedbackText, setFeedbackText] = useState<string>('');
  const onSendFeedbackClick = () => {
    onSendFeedback(feedbackText);
  };

  const title = useMemo(() => isOtherOptionSelected ?
    t('visible-feedback.additional-feedback-field.title-other') : t('visible-feedback.additional-feedback-field.title')
    , [isOtherOptionSelected, t]);

  return (
    <Box direction='vertical' className={css.wrapper}>
      <Text className={css.title} size='medium' weight='normal' dataHook={DATA_HOOKS.ADDITIONAL_FEEDBACK_TITLE}>
        {title}
      </Text>
      <InputArea
        className={css.input}
        placeholder={t('visible-feedback.text-placeholder')}
        dataHook={DATA_HOOKS.ADDITIONAL_FEEDBACK_TEXT}
        rows={5}
        size={'small'}
        maxLength={MAX_ADDITIONAL_TEXT_LENGTH}
        resizable
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.target.value)}
      />

      <Button size='small' disabled={!feedbackText && isOtherOptionSelected} onClick={onSendFeedbackClick} dataHook={DATA_HOOKS.SEND_FEEDBACK_BUTTON} className={css.submitButton}>
        {t('visible-feedback.additional-feedback-field.send')}
      </Button>
    </Box>
  );
};
